// import FeaturesRoutes from './FeaturesRoutes';
import PageRoutes from './PageRoutes';
// import Components from './Components';
// import Apps from './Applications';

const routes = [
  {
    path: '',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "Home" */ '@/view/dashboard/SatoshiCambial2023.vue'),
        meta: {
          title: 'Dashboard - Satoshi Cambial'
        }
      },     
      {
        path: 'meu-satoshi-cambial-2023',
        name: 'meu-satoshi-cambial-2023',
        component: () => import(/* webpackChunkName: "meu-satoshi-cambial-2023" */ '@/view/dashboard/MyHedgeFund2023.vue'),
        meta: {
          title: 'Meu Investimento - Satoshi Cambial 2023'
        },
      }, 
      {
        path: 'satoshi-cambial-2023',
        name: 'satoshi-cambial-2023',
        component: () => import(/* webpackChunkName: "satoshi-cambial-2023" */ '@/view/dashboard/SatoshiCambial2023.vue'),
        meta: {
          title: 'Dashboard - Satoshi Cambial 2023'
        },
      }, 
      {
        path: 'meu-satoshi-cambial-2024',
        name: 'meu-satoshi-cambial-2024',
        component: () => import(/* webpackChunkName: "meu-satoshi-cambial-2024" */ '@/view/dashboard/MyHedgeFund2024.vue'),
        meta: {
          title: 'Meu Investimento - Satoshi Cambial 2024'
        },
      }, 
      {
        path: 'satoshi-cambial-2024',
        name: 'satoshi-cambial-2024',
        component: () => import(/* webpackChunkName: "satoshi-cambial-2024" */ '@/view/dashboard/SatoshiCambial2024.vue'),
        meta: {
          title: 'Dashboard - Satoshi Cambial 2024'
        },
      }, 
      {
        path: 'meu-satoshi-cambial-2025',
        name: 'meu-satoshi-cambial-2025',
        component: () => import(/* webpackChunkName: "meu-satoshi-cambial-2025" */ '@/view/dashboard/MyHedgeFund2025.vue'),
        meta: {
          title: 'Meu Investimento - Satoshi Cambial 2025'
        },
      }, 
      {
        path: 'satoshi-cambial-2025',
        name: 'satoshi-cambial-2025',
        component: () => import(/* webpackChunkName: "satoshi-cambial-2025" */ '@/view/dashboard/SatoshiCambial2025.vue'),
        meta: {
          title: 'Dashboard - Satoshi Cambial 2025'
        },
      }, 
      {
        path: 'meu-satoshi-hedge',
        name: 'meu-satoshi-hedge',
        component: () => import(/* webpackChunkName: "meu-satoshi-hedge" */ '@/view/dashboard/MyHedge.vue'),
        meta: {
          title: 'Meu Investimento - Satoshi Hedge'
        },
      }, 
      {
        path: 'indicador/usdt-brl',
        name: 'usdt',
        component: () => import(/* webpackChunkName: "indicadores" */ '@/view/dashboard/IndicatorUSDT.vue'),
        meta: {
          title: 'Indicador USDT - Satoshi Cambial'
        },
      }, 
      {
        path: 'indicador/usd-brl',
        name: 'usd',
        component: () => import(/* webpackChunkName: "indicadores" */ '@/view/dashboard/IndicatorUSD.vue'),
        meta: {
          title: 'Indicador USD - Satoshi Cambial'
        },
      },
    ],
  },
  {
    path: '/depositos',
    component: () => import(/* webpackChunkName: "depositos" */ '@/view/crud/deposit/Index.vue'),
    children: [
      {
        path: 'lista',
        component: () => import('@/view/crud/deposit/View.vue'),
        meta: {
          title: 'Depósitos - Satoshi Cambial'
        },
      },
      {
        path: 'novo',
        component: () => import('@/view/crud/deposit/addNew.vue'),
        meta: {
          title: 'Novo Depósito - Satoshi Cambial'
        },
      }
    ]
  },
  ...PageRoutes,
  // ...FeaturesRoutes,
  // ...Components,
  // ...Apps,
];

export default routes;
